import React, { useState } from "react"
import { Alert } from "react-bootstrap"
import { sendPaySuccessEmail } from '../api/app';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const PaySuccess = () => {

  const [resultType, setResultType] = useState('');

  function handleClickResend() {
    const userId = getParameterByName('userId');
    sendPaySuccessEmail({userId}).then(() => {
      setResultType('success');
    }).catch(() => {
      setResultType('error');
    });
  }

    return (
      <div className='pay-success-wrapper'>
        {
          resultType === 'success' && (
            <Alert variant='success'>
              Success
            </Alert>
          )
        }

        {
          resultType === 'error' && (
            <Alert variant='danger'>
              Failed
            </Alert>
          )
        }
        <div className={'pay-success'}>
          <div className={'logo-wrapper'}>
            <img
              src={"/assets/icons/cubo-logo.png"}
              alt="brand-logo"
              width={100}/>
          </div>
          <div className="pay-success-title">
            Activate your account
          </div>
          <div className="pay-success-content">
            Thank you for registration. Please activate your account by clicking the link in the email we sent to you.
            <br/>
            If you cannot receive the email, please click Resend button below or contact us at contact@cubo.to
          </div>
          <div className="pay-success-footer">
            <span onClick={handleClickResend}>Resend</span>
          </div>
        </div>
      </div>
    );
}

export default PaySuccess;
